import React from "react"
import { connect } from "react-redux"
import { navigate } from "gatsby"
import { FaSearch, FaFileAlt, FaUser, FaEdit } from "react-icons/fa"
import { useSelector } from "react-redux"

const DashboardIndex = () => {

  const DatosMiSuper = useSelector(state => state.Datosmisuper.DatosMiSuper)

  const handleAlza = () => {

    const encodeidentifier = btoa(DatosMiSuper.run+DatosMiSuper.dv);
    window.open(`https://reclamos-apb.superdesalud.gob.cl/validacion?state=${encodeidentifier}`, '_self');
  
  }

  const handleRedirectFuria = () => {
    window.open(
      `${process.env.GATSBY_RECLAMOS}?run_atu=${DatosMiSuper.run}&dv_atu=${DatosMiSuper.dv}&nombres_atu=${DatosMiSuper.nombre}&apellido_p_atu=${DatosMiSuper.apellido_p}&apellido_m_atu=${DatosMiSuper.apellido_m}`,
      "_self"
    )
  }

  return (
    <>
      <div className="contenedor ">
        <div className="dashboard-option-ss">
        {/* <div className="dashboard-option2-ss">
          <div
            className="option-principal2-ss option-certificados"
            onClick={() => navigate("/inicio/reclamos/")}
          >
            <div className="d-flex">
              <div>
                <FaEdit
                  style={{
                    fontSize: 24,
                    fontWeight: "normal",
                    color: "#0073ba",
                    marginTop: 15,
                  }}
                />
              </div>

              <div className="ml-3">
                <p
                  className="py-0"
                  style={{
                    color: "#444444",
                    fontWeight: "bold",
                    display: "block",
                  }}
                >
                  Ingreso de Reclamos
                </p>
                <small style={{ display: "block" }}>

                Ingresa aquí si deseas reclamar contra una isapre, el FONASA o un prestador de salud (Clínica, Hospital, Consultorio, CESFAM, etc.) o solicitar una mediación por negligencia médica en un establecimiento de salud privado.

                </small>
              </div>
            </div>
          </div>

          <div
            className="option-principal2-ss option-certificados"
            // onClick={() => handleAlza()}
            onClick={() => handleRedirectFuria()}
          >
            <div className="d-flex">
              <div>
                <FaEdit
                  style={{
                    fontSize: 24,
                    fontWeight: "normal",
                    color: "#0073ba",
                    marginTop: 15,
                  }}
                />
              </div>

              <div className="ml-3">
                <p
                  className="py-0"
                  style={{
                    color: "#444444",
                    fontWeight: "bold",
                    display: "block",
                  }}
                >
                  Proceso de Adecuación 2022-2023
                </p>
                <small style={{ display: "block" }}>
                  La Ley N°21.350 faculta a la Superintendencia de Salud a fijar cada año un indicador, el cual será un máximo para aquellas 
                  Isapres que apliquen una variación porcentual al precio base de sus planes de salud. 
                  En el caso de este proceso 2022-2023 la Superintendencia de Salud estableció que ese indicador es de 7,6%
                </small>
              </div>
            </div>
          </div>
        </div> */}
        <div className="option-principal-ss option-certificados" onClick={() => navigate("/inicio/reclamos/")} >
          <div className="d-flex">
            <div>
              <FaEdit
                style={{
                  fontSize: 24,
                  fontWeight: "normal",
                  color: "#0073ba",
                  marginTop: 15,
                }}
              />
            </div>

            <div className="ml-3">
              <p
                className="py-0"
                style={{
                  color: "#444444",
                  fontWeight: "bold",
                  display: "block",
                }}
              >
                Ingreso de Reclamos
              </p>
              <small style={{ display: "block" }}>
                Ingresa aquí si deseas reclamar contra una isapre, el FONASA o un prestador de salud (Clínica, Hospital, Consultorio, CESFAM, etc.) 
                o solicitar una mediación por negligencia médica en un establecimiento de salud privado.
              </small>
            </div>
          </div>
        </div>
        
        <div className="option-principal-ss option-cotizaciones" onClick={() => navigate("/inicio/tramites/")}>
            <div className="d-flex" >
              <div>
                <FaSearch
                className="ml-3 "
                  style={{
                    fontSize: 24,
                    fontWeight: "normal",
                    color: "#F4B41C",
                    marginTop: 15,
                  }}
                />
              </div>

              <div className="ml-3">
                <p
                  className="py-0"
                  style={{
                    color: "#444444",
                    fontWeight: "bold",
                    display: "block",
                  }}
                >
                  Seguimiento de Trámites
                </p>
                <small className="d-block mr-3" >
                Ingresa aquí si deseas hacer seguimiento al estado en que se encuentra un trámite presentado: un reclamo, una solicitud de mediación o una solicitud de ingreso al Registro Nacional de Prestadores Individuales de Salud.

                </small>

                <small className="d-block mr-3 mt-2"> 
                Dependiento la etapa de tramitación de tu trámite podrás adjuntar antecedentes adicionales o presentar un recurso por la resolución emitida.
                </small>
              </div>
            </div>
          </div>
        </div>

      

        <div className="dashboard-option-ss">
          <div className="option-principal-ss option-reclamos"  onClick={() => navigate("/inicio/certificados/")}>
            <div className="d-flex">
              <div>
                <FaFileAlt
                  style={{
                    fontSize: 24,
                    fontWeight: "normal",
                    color: "#00BB3A",
                    marginTop: 15,
                  }}
                />
              </div>

              <div className="ml-3">
                <p
                  className="py-0"
                  style={{
                    color: "#444444",
                    fontWeight: "bold",
                    display: "block",
                  }}
                >
                  Certificados en línea{" "}
                </p>
                <small className="d-block">
                Ingresa aquí si deseas solicitar tu certificado de afiliación en caso que te encuentres vigente en una isapre, un certificado de reclamo en trámite y/o el de inscripción de un profesional en el Registro Nacional de Prestadores Individuales de Salud.

                </small>
              </div>
            </div>
          </div>

          <div className="option-principal-ss option-excedentes" onClick={() => navigate("/inicio/atu/")}>
            <div className="d-flex">
              <div>
                <FaUser
                  style={{
                    fontSize: 24,
                    fontWeight: "normal",
                    color: "#DC3A35",
                    marginTop: 15,
                  }}
                />
              </div>

              <div className="ml-3">
                <p
                  className="py-0"
                  style={{
                    color: "#444444",
                    fontWeight: "bold",
                    display: "block",
                  }}
                >
                  Atención de Personas
                </p>
                <small className="d-block">
                  Ingresa aquí si deseas acceder a las consultas web que has presentado y las respuestas recibidas.

                </small>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const mapStateToProps = state => {
  return state
}

export default connect(mapStateToProps)(DashboardIndex)
